import { Loading } from 'element-ui';
// 开启全屏加载中
const open = () =>  Loading.service();
// 关闭全屏加载中
const close = () => {
    let loadingInstance = Loading.service();
    // this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close({ fullscreen: true });
    // });
};

export default {
    open,close
}