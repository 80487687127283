import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/noper',
    name: 'noper',
    component: () => import('../views/menus/Nopermission.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('../views/Layout.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: { title: '首页' },
        component: () => import('../views/menus/Home.vue')
      },
      { // 用户管理
        path: '/userList',
        redirect: 'userList',
        meta: { title: "用户管理" },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 用户管理
            path: '/userList',
            component: () => import('../views/menus/UserManage/UserList.vue'),
          },
          { // 用户详情
            path: '/UserDetail',
            name: 'UserDetail',
            meta: { title: '用户详情' },
            component: () => import('../views/menus/UserManage/UserDetail.vue')
          },
        ]
      },
      { // 会员管理
        path: '/vipList',
        redirect: 'vipList',
        meta: { title: "会员管理" },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 用户管理
            path: '/vipList',
            component: () => import('../views/menus/UserManage/VipList.vue'),
          },
          { // 用户详情
            path: '/vipType',
            name: 'vipType',
            meta: { title: '会员类型' },
            component: () => import('../views/menus/UserManage/VipType.vue')
          },
        ]
      },
      { // 积分管理管理
        path: '/scoreList',
        redirect: 'scoreList',
        meta: { title: "积分管理" },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 积分管理
            path: '/scoreList',
            component: () => import('../views/menus/UserManage/ScoreList.vue'),
          },
          // { // 用户详情
          //   path: '/UserDetail',
          //   name: 'UserDetail',
          //   meta:{title:'用户详情'},
          //   component: () => import('../views/menus/UserManage/UserDetail.vue')
          // },
        ]
      },
    ]
  },

  {
    path: '/gasStations',
    name: 'lGasStations',
    meta: { title: "充电站管理" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 充电站管理
        path: '/gasStations',
        redirect: 'gasStations',
        meta: { title: "充电站管理" },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 添加充电站
            path: '/gasStations',
            component: () => import('../views/menus/GesStations/GesStationsDeCharge.vue'),
          },
          { // 添加充电站
            path: '/addGesStation',
            name: 'addGesStation',
            meta: { title: "添加充电站" },
            component: () => import('../views/menus/GesStations/AddGesStation/AddGesStation.vue')
          },
        ]
      },

      { // 充电桩
        path: '/ChargingPile',
        name: 'ChargingPile',
        meta: { title: '充电桩管理' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 充电桩管理
            path: '/ChargingPile',
            component: () => import('../views/menus/GesStations/ChargingPile.vue'),
          },
          { // 添加充电桩
            path: '/addChargePile',
            name: 'addChargePile',
            meta: { title: '添加充电桩' },
            component: () => import('../views/menus/GesStations/AddChargePile/AddChargePile.vue')
          },
        ]
      },
      { // 充电桩
        path: '/cabinet',
        name: 'lcabinet',
        meta: { title: '共享充电宝' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 充电桩管理
            path: '/charger',
            meta: { title: '充电宝管理' },
            component: () => import('../views/menus/GesStations/charger/ChargerManage.vue'),
          },
          { // 添加充电桩
            path: '/cabinet',
            name: 'cabinet',
            meta: { title: '机柜管理' },
            component: () => import('../views/menus/GesStations/charger/CabinetManage.vue')
          },
        ]
      },
      {
        path: '/ChargingGun',
        name: 'ChargingGun',
        meta: { title: '充电枪管理' },
        component: () => import('../views/menus/GesStations/ChargingGun.vue')
      },
      {
        path: '/parking',
        name: 'parking',
        meta: { title: '停车场管理' },
        component: () => import('../views/menus/GesStations/ParkingManage.vue')
      },
      {
        path: '/groundLock',
        name: 'groundLock',
        meta: { title: '地锁管理' },
        component: () => import('../views/menus/GesStations/GroundLock.vue')
      },
      {
        path: '/chair',
        name: 'chair',
        meta: { title: '按摩椅管理' },
        component: () => import('../views/menus/GesStations/ChairManage.vue')
      },
      {
        path: '/sale',
        name: 'sale',
        meta: { title: '售卖机管理' },
        component: () => import('../views/menus/GesStations/SaleManage.vue')
      },
      {
        path: '/wash',
        name: 'wash',
        meta: { title: '洗车机管理' },
        component: () => import('../views/menus/GesStations/WashManage.vue')
      },
      {
        path: '/parkingSpace',
        name: 'parkingSpace',
        meta: { title: '停车位管理' },
        component: () => import('../views/menus/GesStations/ParkingSpace.vue')
      },
      {
        path: '/ruleManage',
        name: 'ruleManage',
        meta: { title: '费用规则' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 费用规则列表
            path: '/ruleManage',
            component: () => import('../views/menus/GesStations/Rule.vue'),
          },
          { // 添加费用规则
            path: '/addRule',
            name: 'addRule',
            meta: { title: '新增费用规则' },
            component: () => import('../views/menus/GesStations/addRule/addRule.vue')
          },
          { // 费用规则详情
            path: '/ruleDetail',
            name: 'ruleDetail',
            meta: { title: '费用规则详情' },
            component: () => import('../views/menus/GesStations/addRule/RuleDetail.vue')
          },
        ]
      },
    ]
  },
  { // 商品管理
    path: '/goodManage',
    name: 'lgoodManage',
    meta: { title: "商品管理" },
    component: () => import('../views/Layout.vue'),
    children: [
      {
        path: '/goodManage',
        name: 'goodManage',
        meta: { title: '商品管理' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 充电桩管理
            path: '/goodManage',
            component: () => import('../views/menus/Goods/GoodManage.vue'),
          },
          { // 添加充电桩
            path: '/addGood',
            name: 'addGood',
            meta: { title: '新增商品' },
            component: () => import('../views/menus/Goods/AddGoods.vue')
          },
        ]
      },
      {
        path: '/classify',
        name: 'classify',
        meta: { title: '商品分类' },
        component: () => import('../views/menus/Goods/Classify.vue')
      },
      {
        path: '/brand',
        name: 'brand',
        meta: { title: '商品品牌' },
        component: () => import('../views/menus/Goods/Brand.vue')
      },
      {
        path: '/outbound',
        name: 'outbound',
        meta: { title: '商品出库' },
        component: () => import('../views/menus/Goods/Outbound.vue')
      },
      {
        path: '/specifications',
        name: 'specifications',
        meta: { title: '商品规格' },
        component: () => import('../views/menus/Goods/Specifications.vue')
      },

      {
        path: '/goodTag',
        name: 'goodTag',
        meta: { title: '商品标签' },
        component: () => import('../views/menus/Goods/GoodTag.vue')
      },

      {
        path: '/evaluate',
        name: 'evaluate',
        meta: { title: '商品评论' },
        component: () => import('../views/menus/Goods/Evaluate.vue')
      },

      {
        path: '/goodRule',
        name: 'goodRule',
        meta: { title: '运费模板' },
        component: () => import('../views/menus/Goods/GoodRule.vue')
      },
    ]
  },
  {
    path: '/charging',
    name: 'lcharging',
    meta: { title: "订单管理" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 订单管理
        path: '/placeholder',
        name: 'placeholder',
        meta: { title: '占位订单' },
        component: { render(c) { return c('router-view') } },
        children: [
          {
            path: '/placeholder',
            component: () => import('../views/menus/OrderManage/Placeholder.vue'),
          },
          {
            path: '/placeholderDetail',
            name: 'placeholderDetail',
            meta: { title: '占位订单-详情' },
            component: () => import('../views/menus/OrderManage/PlaceholderDetail/PlaceholderDetail.vue')
          },

        ]
      },
      {
        path: '/charging',
        name: 'charging',
        meta: { title: '充电订单' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/charging',
            component: () => import('../views/menus/OrderManage/Charging.vue'),
          },
          { // 订单管理
            path: '/chargingDetail',
            name: 'chargingDetail',
            meta: { title: '充电订单-详情' },
            component: () => import('../views/menus/OrderManage/ChargingDetail/ChargingDetail.vue')
          },
        ]
      },
      {
        path: '/chairOrder',
        name: 'chairOrder',
        meta: { title: '按摩椅订单' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/chairOrder',
            component: () => import('../views/menus/OrderManage/ChairOrder.vue'),
          },
          // { // 订单管理
          //   path: '/chairOrder',
          //   name: 'chairOrder',
          //   meta:{title:'充电订单-详情'},
          //   component: () => import('../views/menus/OrderManage/ChairOrder.vue')
          // },
        ]
      },
      {
        path: '/connect',
        name: 'lconnect',
        meta: { title: '互联订单' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/connect',
            component: () => import('../views/menus/OrderManage/ConnectOrder.vue'),
          },
          // { // 订单管理
          //   path: '/connectDetail',
          //   name: 'connect',
          //   meta:{title:'互联互通订单-详情'},
          //   component: () => import('../views/menus/OrderManage/ChargingDetail/ChargingDetail.vue')
          // },
        ]
      },
      {
        path: '/saleOrder',
        name: 'saleOrder',
        meta: { title: '售卖机订单' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/saleOrder',
            component: () => import('../views/menus/OrderManage/SaleOrder.vue'),
          },
          // { // 订单管理
          //   path: '/goodOrderDetail',
          //   name: 'goodOrderDetail',
          //   meta:{title:'互联互通订单-详情'},
          //   component: () => import('../views/menus/OrderManage/GoodsOrder/GoodsOrderDetail.vue')
          // },
        ]
      },
      {
        path: '/washOrder',
        name: 'lwashOrder',
        meta: { title: '洗车机订单' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/washOrder',
            component: () => import('../views/menus/OrderManage/WashingOrder.vue'),
          },
          // { // 订单管理
          //   path: '/goodOrderDetail',
          //   name: 'goodOrderDetail',
          //   meta:{title:'互联互通订单-详情'},
          //   component: () => import('../views/menus/OrderManage/GoodsOrder/GoodsOrderDetail.vue')
          // },
        ]
      },
      {
        path: '/chargerOrder',
        name: 'lchargerOrder',
        meta: { title: '充电宝订单' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/chargerOrder',
            component: () => import('../views/menus/OrderManage/ChargerOrder.vue'),
          },
          // { // 订单管理
          //   path: '/goodOrderDetail',
          //   name: 'goodOrderDetail',
          //   meta:{title:'互联互通订单-详情'},
          //   component: () => import('../views/menus/OrderManage/GoodsOrder/GoodsOrderDetail.vue')
          // },
        ]
      },
      {
        path: '/parkOrder',
        name: 'parkOrder',
        meta: { title: '停车订单' },
        component: () => import('../views/menus/OrderManage/ParkingOrder.vue')
      },
      {
        path: '/drawback',
        name: 'drawback',
        meta: { title: '退款记录' },
        component: () => import('../views/menus/OrderManage/Drawback.vue')
      },
      {
        path: '/withdrawPlaceholder',
        name: 'withdrawPlaceholder',
        meta: { title: '退款-详情' },
        component: () => import('../views/menus/OrderManage/WithdrawDetail/WithdrawPlaceholder.vue')
      },
      {
        path: '/withdrawCharging',
        name: 'withdrawCharging',
        meta: { title: '退款-充电' },
        component: () => import('../views/menus/OrderManage/WithdrawDetail/WithdrawCharging.vue')
      },

      {
        path: '/productOrder',
        name: 'productOrder',
        meta: { title: '商品订单管理' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/productOrder',
            component: () => import('../views/menus/OrderManage/productOrder/ProductOrder.vue'),
          },
          { // 订单管理
            path: '/goodOrderDetail',
            name: 'goodOrderDetail',
            meta:{title:'商品订单详情'},
            component: () => import('../views/menus/OrderManage/productOrder/ProductOrderDetail.vue')
          },
        ]
      },

      {
        path: '/refund',
        name: 'refund',
        meta: { title: '商品售后管理' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/refund',
            redirect: 'refund',
            meta: { title: '退款订单' },
            component: { render(c) { return c('router-view') } },
            children: [
              { // 退款订单
                path: '/refund',
                name:'refund',
                component: () => import('../views/menus/OrderManage/Service/RefundOrder.vue'),
              },
              { // 退款订单详情
                path: '/refund/refundDetail',
                name: 'refundDetail',
                meta: { title: "退款订单详情" },
                component: () => import('../views/menus/OrderManage/Service/FundDetail.vue')
              },
            ]
          },
          { // 退货
            path: '/return',
            redirect: 'return',
            meta: { title: '退货订单' },
            component: { render(c) { return c('router-view') } },
            children: [
              { // 退货订单
                path: '/return',
                meta: { title: '退货订单' },
                component: () => import('../views/menus/OrderManage/Service/ReturnGood.vue')
              },
              { // 退货订单详情
                path: '/return/returnDetail',
                name: 'returnDetail',
                meta: { title: "退货订单详情" },
                component: () => import('../views/menus/OrderManage/Service/ReturnDetail.vue')
              },
            ]
          },
          { // 退货
            path: '/exchange',
            redirect: 'exchange',
            meta: { title: '换货订单' },
            component: { render(c) { return c('router-view') } },
            children: [
              { // 退货订单
                path: '/exchange',
                meta: { title: '换货订单' },
                component: () => import('../views/menus/OrderManage/Service/ExchangeOrder.vue')
              },
              { // 退货订单详情
                path: '/exchange/exchangeDetail',
                name: 'returnDetail',
                meta: { title: "换货订单详情" },
                component: () => import('../views/menus/OrderManage/Service/ExchangeDetail.vue')
              },
            ]
          },
        ]
      },
      { // 充电桩
        path: '/cabinet',
        name: 'lcabinet',
        meta: { title: '共享充电宝' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 充电桩管理
            path: '/charger',
            meta: { title: '充电宝管理' },
            component: () => import('../views/menus/GesStations/charger/ChargerManage.vue'),
          },
          { // 添加充电桩
            path: '/cabinet',
            name: 'cabinet',
            meta: { title: '机柜管理' },
            component: () => import('../views/menus/GesStations/charger/CabinetManage.vue')
          },
        ]
      },
      {
        path: '/orderConfig',
        name: 'orderConfig',
        meta: { title: '订单配置' },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 订单管理
            path: '/orderConfig',
            meta: { title: '订单配置' },
            component: () => import('../views/menus/OrderManage/OrderConfig/OrderConfig.vue'),
          },
          { // 收货配置
            path: '/getConfig',
            name: 'getConfig',
            meta: { title: '收货配置' },
            component: () => import('../views/menus/OrderManage/OrderConfig/getConfig.vue')
          },
          { // 收货配置
            path: '/serviceConfig',
            name: 'serviceConfig',
            meta: { title: '售后配置' },
            component: () => import('../views/menus/OrderManage/OrderConfig/serviceConfig.vue')
          },
        ]
      },
    ]
  },
  {
    path: '/recharge',
    name: 'lRecharge',
    meta: { title: "资产管理" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 资产管理
        path: '/recharge',
        name: 'recharge',
        meta: { title: '充值记录' },
        component: () => import('../views/menus/AssetManage/RechargeRecord.vue')
      },
      {
        path: '/withdraw',
        name: 'withdraw',
        meta: { title: '提现记录' },
        component: () => import('../views/menus/AssetManage/WithdrawRecord.vue')
      },
      {
        path: '/cardManage',
        name: 'cardManage',
        meta: { title: '储值卡管理' },
        component: { render(c) { return c('router-view') } },
        children: [
          {
            path: '/cardManage',
            component: () => import('../views/menus/AssetManage/StoredCardList.vue')
          },
          {
            path: '/cardCharge',
            name: 'cardCharge',
            meta: { title: '储值卡充值' },
            component: () => import('../views/menus/AssetManage/StoredCardCharge.vue')
          },
        ]
      }
    ]
  },
  {
    path: '/drainage',
    name: 'Drainage',
    meta: { title: "商机引流" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 资产管理
        path: '/Group',
        name: 'Group',
        meta: { title: '社群引流' },
        component: () => import('../views/menus/Drainage/Group.vue')
      },
    ]
  },
  {
    path: '/cooperation',
    name: 'lCooperation',
    meta: { title: "商务管理" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 资产管理
        path: '/cooperation',
        name: 'cooperation',
        meta: { title: '商务合作' },
        component: () => import('../views/menus/Commercial/Cooperation.vue')
      },
    ]
  },
  {
    path: '/adManage',
    name: 'lAdManage',
    meta: { title: "运营管理" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 运营管理
        path: '/adManage',
        name: 'adManage',
        meta: { title: '广告管理' },
        component: () => import('../views/menus/OperationManage/Advertisement.vue')
      },
      { // 运营管理
        path: '/bannerManage',
        name: 'bannerManage',
        meta: { title: 'banner管理' },
        component: () => import('../views/menus/OperationManage/BannerManage.vue')
      },
      // {
      //   path: '/helpCenter',
      //   name: 'helpCenter',
      //   meta:{title:'帮助中心'},
      //   component: () => import('../views/menus/OperationManage/HelpCenter.vue')
      // },

    ]
  },
  {
    path: '/alarmCenter',
    name: 'AlarmCenter',
    meta: { title: "运营监测" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 运营监测
        path: '/pileAlarm',
        name: 'pileAlarm',
        meta: { title: '告警中心' },
        component: { render(c) { return c('router-view') } },
        children: [
          {
            path: '/pileAlarm',
            meta: { title: '充电桩告警' },
            component: () => import('../views/menus/OperationMonitoring/AlarmCenter/PileAlarm.vue'),
          },
          {
            path: '/lockAlarm',
            name: 'lockAlarm',
            meta: { title: '地锁告警' },
            component: () => import('../views/menus/OperationMonitoring/AlarmCenter/LockAlarm.vue'),
          },
        ]
      },
      {
        path: '/troubleshooting',
        name: 'Troubleshooting',
        meta: { title: '故障报修' },
        component: () => import('../views/menus/OperationMonitoring/Troubleshooting.vue')
      },
    ]
  },
// 积分商城
  {
    path: '/score',
    name: 'score',
    meta: { title: "积分管理" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 运营监测
        path: '/scoreMarket',
        name: 'scoreMarket',
        meta: { title: '积分商城' },
        component: { render(c) { return c('router-view') } },
        children: [
          {
            path: '/scoreMarket',
            meta: { title: '积分商城' },
            component: () => import('../views/menus/ScoreManage/ScoreMarket.vue'),
          },
          // {
          //   path: '/lockAlarm',
          //   name: 'lockAlarm',
          //   meta: { title: '地锁告警' },
          //   component: () => import('../views/menus/OperationMonitoring/AlarmCenter/LockAlarm.vue'),
          // },
        ]
      },
      {
        path: '/changeRecord',
        name: 'changeRecord',
        meta: { title: '兑换记录' },
        component: () => import('../views/menus/ScoreManage/ChangeRecord.vue'),
      },
    ]
  },
  {
    path: '/couponManage',
    name: 'lCouponManage',
    meta: { title: "营销管理" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 营销管理
        path: '/couponManage',
        name: 'couponManage',
        meta: { title: '优惠券管理' },
        component: () => import('../views/menus/MarketingManage/CouponManage.vue')
      },
      { // 营销管理
        path: '/couponDetail',
        name: 'couponDetail',
        meta: { title: '优惠券详情' },
        component: () => import('../views/menus/MarketingManage/CouponDetail.vue')
      },
      { // 营销管理
        path: '/addCoupon',
        name: 'addCoupon',
        meta: { title: '新增优惠券' },
        component: () => import('../views/menus/MarketingManage/AddCoupon.vue')
      },
      { // 商品优惠券管理
        path: '/goodCouponList',
        redirect: 'goodCouponList',
        meta: { title: "商品优惠券管理" },
        component: { render(c) { return c('router-view') } },
        children: [
          { // 商品优惠券管理
            path: '/goodCouponList',
            component: () => import('../views/menus/MarketingManage/goodCoupon/GoodCouponList.vue'),
          },
          { // 营销管理
            path: '/goodCouponAdd',
            name: 'goodCouponAdd',
            meta: { title: '商品优惠券新增' },
            component: () => import('../views/menus/MarketingManage/goodCoupon/GoodCouponAdd.vue')
          },
        ]
      },
    ]
  },
  {
    path: '/accountSetting',
    name: 'lAccountSetting',
    meta: { title: "系统设置" },
    component: () => import('../views/Layout.vue'),
    children: [
      { // 系统设置
        path: '/accountSetting',
        name: 'accountSetting',
        meta: { title: '账户管理' },
        component: () => import('../views/menus/SystemSettings/AccountSetting.vue')
      },
      {
        path: '/roleManage',
        name: 'roleManage',
        meta: { title: '角色管理' },
        component: () => import('../views/menus/SystemSettings/RoleManage.vue')
      },
      {
        path: '/departmentManage',
        name: 'departmentManage',
        meta: { title: '部门管理' },
        component: () => import('../views/menus/SystemSettings/DepartmentManage.vue')
      },
      {
        path: '/logManage',
        name: 'logManage',
        meta: { title: '日志管理' },
        component: () => import('../views/menus/SystemSettings/LogManage.vue'),
      },
      { // 登录日志
        path: '/loginManage',
        name: 'loginManage',
        meta: { title: '登录日志' },
        component: () => import('../views/menus/SystemSettings/logs/LoginManage.vue')
      },
      { // 操作日志
        path: '/OperateManage',
        name: 'OperateManage',
        meta: { title: '操作日志' },
        component: () => import('../views/menus/SystemSettings/logs/OperateManage.vue')
      },
      {
        path: '/basicSettings',
        name: 'basicSettings',
        meta: { title: '基本设置' },
        component: () => import('../views/menus/SystemSettings/BasicSettings.vue')
      },
      {
        path: '/personalInformation',
        name: 'personalInformation',
        meta: { title: '个人信息' },
        component: () => import('../views/menus/SystemSettings/PersonalInformation.vue')
      },
    ]
  },
]

// const originalPush = VueRouter.prototype.push

// VueRouter.prototype.push = function push(location) {

//   return originalPush.call(this,location).catch(err => err)

// }




// 先把vueRouter原型对象的push、replace保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
// 重写push和replace
// 第一个参数，告诉原来push方法，往哪里跳转，传递哪些参数
// 第二个参数是成功的回调
// 第三个参数是失败的回调
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    // call和apply区别
    // 相同点：都可以调用函数一次，都可以篡改函数的上下文一次
    // 不同点：call和apply传递参数：call传递参数用逗号隔开，apply方法传递数组
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => { }, () => { })
  }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
