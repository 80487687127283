import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/icon/iconfont.css'
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'reset.css'
Vue.use(ElementUI);
import 'lib-flexible/flexible.js'
// 联动
// import VDistpicker from 'v-distpicker'
// Vue.component('v-distpicker', VDistpicker)

// 全屏加载
import load from '@/utils/loading.js'
Vue.prototype.$fullPageLoading = load

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
